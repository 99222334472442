/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

/* HTML5 display-role reset for older browsers */
#GN-sub-nav div,
#GN-sub-nav span,
#GN-sub-nav h1,
#GN-sub-nav h2,
#GN-sub-nav h3,
#GN-sub-nav h4,
#GN-sub-nav h5,
#GN-sub-nav h6,
#GN-sub-nav p,
#GN-sub-nav blockquote,
#GN-sub-nav a,
#GN-sub-nav abbr,
#GN-sub-nav acronym,
#GN-sub-nav address,
#GN-sub-nav del,
#GN-sub-nav dfn,
#GN-sub-nav em,
#GN-sub-nav img,
#GN-sub-nav ins, q,
#GN-sub-nav strike,
#GN-sub-nav strong,
#GN-sub-nav sub,
#GN-sub-nav sup,
#GN-sub-nav b,
#GN-sub-nav i,
#GN-sub-nav dl,
#GN-sub-nav dt,
#GN-sub-nav dd,
#GN-sub-nav ol,
#GN-sub-nav ul,
#GN-sub-nav li,
#GN-sub-nav fieldset,
#GN-sub-nav form,
#GN-sub-nav label,
#GN-sub-nav legend,
#GN-sub-nav table,
#GN-sub-nav caption,
#GN-sub-nav tbody,
#GN-sub-nav tfoot,
#GN-sub-nav thead,
#GN-sub-nav tr,
#GN-sub-nav th,
#GN-sub-nav td,
#GN-sub-nav article,
#GN-sub-nav aside,
#GN-sub-nav details,
#GN-sub-nav embed,
#GN-sub-nav figure,
#GN-sub-nav figcaption,
#GN-sub-nav footer,
#GN-sub-nav header,
#GN-sub-nav menu,
#GN-sub-nav nav,
#GN-sub-nav output,
#GN-sub-nav section,
nav#GN-sub-nav nav {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   line-height: 1;
   vertical-align: baseline;
}

#GN-sub-nav article,
#GN-sub-nav aside,
#GN-sub-nav figcaption,
#GN-sub-nav figure,
#GN-sub-nav footer,
#GN-sub-nav header,
#GN-sub-nav nav,
#GN-sub-nav section,
nav#GN-sub-nav nav {
   display: block;
}

#GN-sub-nav ol,
#GN-sub-nav ul {
   list-style: none;
}

#GN-sub-nav table {
   border-collapse: collapse;
   border-spacing: 0;
}