$fallback           : Helvetica, Arial, sans-serif;

@font-face{
    font-family: 'NYUPerstare';
    src: url('https://webstatic.nyu.edu/fonts/NYUPerstare-VF.woff2') format('woff2');
}

@font-face{
    font-family: 'NYUPerstare-Italic';
    src: url('https://webstatic.nyu.edu/fonts/NYUPerstare-Italic-VF.woff2') format('woff2');
}

@mixin font-perstare($family, $weight){
    font-family: NYUPerstare, $fallback;
    font-weight: $weight;
    font-style: normal;
    font-stretch: normal;
}

@mixin font-perstare-italic($family){
    font-family: NYUPerstare-Italic, $fallback;
    font-style: italic;
    font-stretch: normal;
}

/* Perstare font weights:
* Thin - 100
* Light - 300
* Regular - 400
* Medium - 500
* Bold - 700
* Black - 800
* Ultra - 900 */