$color-nyu-purple         : rgb(87, 6, 140); // #57068c // Main banner color
$color-nyu-darker-purple  : rgb(51, 6, 98); // #330662

$color-light-blue   : rgb(134, 195, 232); // #86c3e8
$color-blue         : rgb(27, 167, 212); // #1ba7d4
$color-amaranth     : rgb(230, 51, 78); // #e6334e
$color-lavender     : rgb(152, 133, 174); // #9885ae
$color-dove-gray    : rgb(109, 109, 109); // #6d6d6d

$color-school-hover : #CA8CE9;
$color-tab-focus: #3dbbdb;

$color-new-york     : rgb(169, 178, 64); // #a9b240
$color-abu-dhabi    : #02b79d; // #de7005
$color-shanghai     : #dd7127; // #e9304a

$color-white        : rgb(255, 255, 255); // #ffffff

$color-overlay-mask : rgba(130, 140, 150, .8); // #828c96. .8 opacity

$time-quantum       : 1s;

// FONTS //
$fontdir            : '/fonts/';
